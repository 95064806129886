import React from 'react'
import { Box } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const styles = {
  sidebar: {
    display: 'block',
    mt: [4, null, null, 0],
    flexBasis: ['100%', null, null, '1/3'],
    ml: [0, null, null, 3]
  }
}

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack direction={['column', null, null, 'row']}>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='FlexiBlog theme comes with a pre-made contact form component.
					You can integrate this form with serverless services such as Formspree, Getform,
					FormKeep and others to receive form submissions via email.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Box sx={styles.sidebar}>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Box>
    </Stack>
  </Layout>
)

export default PageContact
